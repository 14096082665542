/** @jsx $jsx */
import { $jsx } from 'framework7';
import i18next from '../../src/i18n.js';
function framework7Component(props, ctx) {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
   <div class="page page-home">
      <!-- Page Content -->
      <div class="page-content content-area pt-40 pb-0">
         <a href="#english-version">English version follows bellow</a>
         <h1>Политика за поверителност</h1>
         <p>Последна актуализация: 27 октомври 2024 г.</p>
         <p>Тази Политика за поверителност описва нашите политики и процедури относно събирането, използването и разкриването на вашата информация, когато използвате Услугата и ви информира за вашите права за поверителност и как законът ви защитава.</p>
         <p>Ние използваме вашите лични данни, за да предоставяме и подобряваме Услугата. Използвайки Услугата, вие се съгласявате със събирането и използването на информация в съответствие с тази Политика за поверителност. Тази Политика за поверителност е създадена с помощта на <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Безплатен генератор на политики за поверителност</a>.</p>
         <h2>Тълкуване и дефиниции</h2>
         <h3>Тълкуване</h3>
         <p>Думите, чиито начални букви са с главни букви, имат значения, определени при следните условия. Следните дефиниции ще имат същото значение, независимо дали се появяват в единствено или множествено число.</p>
         <h3>Дефиниции</h3>
         <p>За целите на тази Политика за поверителност:</p>
         <ul>
         <li>
         <p><strong>Акаунт</strong> означава уникален акаунт, създаден за вас, за да получите достъп до нашата Услуга или части от нашата Услуга.</p>
         </li>
         <li>
         <p><strong>Афилиейт</strong> означава субект, който контролира, е контролиран от или е под общ контрол с дадена страна, където "контрол" означава притежание на 50% или повече от акциите, дяловия интерес или други ценни книжа, които имат право да гласуват за избор на директори или друга управляваща власт.</p>
         </li>
         <li>
         <p><strong>Компания</strong> (наричана или "Компанията", "Ние", "Нас" или "Нашата" в това Споразумение) се отнася до Studio Reborn LLC, София.</p>
         </li>
         <li>
         <p><strong>Бисквитки</strong> са малки файлове, които се поставят на вашия компютър, мобилно устройство или друго устройство от уебсайт, съдържащи подробности за вашата история на сърфиране на този уебсайт сред многото му приложения.</p>
         </li>
         <li>
         <p><strong>Държава</strong> се отнася до: България</p>
         </li>
         <li>
         <p><strong>Устройство</strong> означава всяко устройство, което може да получи достъп до Услугата, като компютър, мобилен телефон или цифров таблет.</p>
         </li>
         <li>
         <p><strong>Лични данни</strong> са всяка информация, която се отнася до идентифицирано или идентифицируемо физическо лице.</p>
         </li>
         <li>
         <p><strong>Услуга</strong> се отнася до Уебсайта.</p>
         </li>
         <li>
         <p><strong>Доставчик на услуги</strong> означава всяко физическо или юридическо лице, което обработва данните от името на Компанията. Това се отнася до трети страни компании или лица, наети от Компанията, за да улеснят Услугата, да предоставят Услугата от името на Компанията, да извършват услуги, свързани с Услугата, или да помагат на Компанията в анализа на използването на Услугата.</p>
         </li>
         <li>
         <p><strong>Данни за използване</strong> се отнася до данни, събирани автоматично, генерирани от използването на Услугата или от самата инфраструктура на Услугата (например продължителността на посещение на страница).</p>
         </li>
         <li>
         <p><strong>Уебсайт</strong> се отнася до Oopsy.app, достъпен от <a href="https://oopsy.app" rel="external nofollow noopener" target="_blank">https://oopsy.app</a></p>
         </li>
         <li>
         <p><strong>Вие</strong> означава физическото лице, което получава достъп или използва Услугата, или компанията, или друго юридическо лице, от името на което такова физическо лице получава достъп или използва Услугата, както е приложимо.</p>
         </li>
         </ul>
         <h2>Събиране и използване на вашите лични данни</h2>
         <h3>Видове данни, които събираме</h3>
         <h4>Лични данни</h4>
         <p>Докато използвате нашата Услуга, ние може да ви помолим да ни предоставите определена лична информация, която може да бъде използвана за контакт или идентификация. Личната информация може да включва, но не се ограничава до:</p>
         <ul>
         <li>
         <p>Имейл адрес</p>
         </li>
         <li>
         <p>Име и фамилия</p>
         </li>
         <li>
         <p>Данни за използване</p>
         </li>
         </ul>
         <h4>Данни за използване</h4>
         <p>Данни за използване се събират автоматично при използване на Услугата.</p>
         <p>Данни за използване може да включват информация като IP адрес на вашето устройство, тип браузър, версия на браузъра, страниците на нашата Услуга, които посещавате, времето и датата на вашето посещение, времето, прекарано на тези страници, уникални идентификатори на устройства и други диагностични данни.</p>
         <p>Когато достъпвате Услугата чрез мобилно устройство, ние може автоматично да събираме определена информация, включително, но не само, типа на мобилното устройство, което използвате, уникалния идентификатор на вашето мобилно устройство, IP адреса на вашето мобилно устройство, операционната система на вашето мобилно устройство, типа на мобилния интернет браузър, който използвате, уникални идентификатори на устройства и други диагностични данни.</p>
         <p>Ние може също да събираме информация, която вашият браузър изпраща, когато посещавате нашата Услуга или когато достъпвате Услугата чрез мобилно устройство.</p>
         <h4>Технологии за проследяване и бисквитки</h4>
         <p>Ние използваме бисквитки и подобни технологии за проследяване, за да следим активността на нашата Услуга и да съхраняваме определена информация. Технологиите за проследяване, които използваме, включват маяци, тагове и скриптове за събиране и проследяване на информация и за подобряване и анализ на нашата Услуга. Технологиите, които използваме, могат да включват:</p>
         <ul>
            <li><strong>Бисквитки или браузърни бисквитки.</strong> Бисквитката е малък файл, поставен на Вашето Устройство. Можете да инструктирате Вашия браузър да отказва всички бисквитки или да указва кога се изпраща бисквитка. Въпреки това, ако не приемате бисквитки, може да не успеете да използвате някои части от нашата Услуга. Освен ако не сте настроили Вашия браузър да отказва бисквитки, нашата Услуга може да използва бисквитки.</li>
            <li><strong>Уеб маяци.</strong> Определени секции на нашата Услуга и нашите имейли могат да съдържат малки електронни файлове, известни като уеб маяци (наричани също прозрачни GIF, пикселни тагове и еднопикселни GIF), които позволяват на Компанията, например, да брои потребителите, които са посетили тези страници или отворили имейл и за други свързани статистики на уебсайта (например записване на популярността на определена секция и проверка на системната и сървърната цялост).</li>
            </ul>
            <p>Бисквитките могат да бъдат &quot;Постоянни&quot; или &quot;Сесийни&quot; бисквитки. Постоянните бисквитки остават на Вашия личен компютър или мобилно устройство, когато сте офлайн, докато сесийните бисквитки се изтриват веднага щом затворите Вашия уеб браузър. Научете повече за бисквитките в <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">уебсайта на Free Privacy Policy</a>.</p>
            <p>Ние използваме както сесийни, така и постоянни бисквитки за целите, посочени по-долу:</p>
            <ul>
            <li>
            <p><strong>Необходими / Основни бисквитки</strong></p>
            <p>Тип: Сесийни бисквитки</p>
            <p>Администрирани от: Нас</p>
            <p>Цел: Тези бисквитки са от съществено значение за предоставянето на услуги, достъпни чрез Уебсайта, и за да Ви позволят да използвате някои от неговите функции. Те помагат за удостоверяване на потребителите и предотвратяване на измамна употреба на потребителски акаунти. Без тези бисквитки услугите, които сте поискали, не могат да бъдат предоставени, и ние използваме тези бисквитки само за да Ви предоставим тези услуги.</p>
            </li>
            <li>
            <p><strong>Бисквитки за приемане на политика за бисквитки / Уведомление</strong></p>
            <p>Тип: Постоянни бисквитки</p>
            <p>Администрирани от: Нас</p>
            <p>Цел: Тези бисквитки идентифицират дали потребителите са приели използването на бисквитки на Уебсайта.</p>
            </li>
            <li>
            <p><strong>Функционални бисквитки</strong></p>
            <p>Тип: Постоянни бисквитки</p>
            <p>Администрирани от: Нас</p>
            <p>Цел: Тези бисквитки ни позволяват да запомним изборите, които правите, когато използвате Уебсайта, като например запомняне на вашите данни за вход или езикови предпочитания. Целта на тези бисквитки е да Ви предоставят по-личен опит и да избегнат необходимостта да въвеждате отново вашите предпочитания всеки път, когато използвате Уебсайта.</p>
            </li>
            </ul>
            <p>За повече информация относно бисквитките, които използваме, и вашите избори относно бисквитките, моля, посетете нашата Политика за бисквитки или секцията за бисквитки на нашата Политика за поверителност.</p>
            <h3>Използване на вашите лични данни</h3>
            <p>Компанията може да използва Лични данни за следните цели:</p>
            <ul>
            <li>
            <p><strong>За предоставяне и поддържане на нашата Услуга</strong>, включително за наблюдение на използването на нашата Услуга.</p>
            </li>
            <li>
            <p><strong>За управление на вашия акаунт:</strong> за управление на вашата регистрация като потребител на Услугата. Личните данни, които предоставяте, могат да Ви дадат достъп до различни функционалности на Услугата, които са достъпни за Вас като регистриран потребител.</p>
            </li>
            <li>
            <p><strong>За изпълнение на договор:</strong> разработване, изпълнение и осъществяване на договора за покупка на продуктите, артикулите или услугите, които сте закупили, или на всеки друг договор с Нас чрез Услугата.</p>
            </li>
            <li>
            <p><strong>За да се свържем с Вас:</strong> За да се свържем с Вас чрез имейл, телефонни обаждания, SMS или други еквивалентни форми на електронна комуникация, като например push известия на мобилни приложения относно актуализации или информативни съобщения, свързани с функционалностите, продуктите или договорените услуги, включително актуализации на сигурността, когато е необходимо или разумно за тяхното изпълнение.</p>
            </li>
            <li>
            <p><strong>За да Ви предоставим</strong> новини, специални оферти и обща информация за други стоки, услуги и събития, които предлагаме, които са подобни на тези, които вече сте закупили или запитали, освен ако не сте избрали да не получавате такава информация.</p>
            </li>
            <li>
            <p><strong>За управление на вашите заявки:</strong> За да отговаряме и управляваме вашите заявки към Нас.</p>
            </li>
            <li>
            <p><strong>За бизнес трансфери:</strong> Може да използваме вашата информация за оценка или извършване на сливане, разпределение, преструктуриране, реорганизация, разпускане или друга продажба или прехвърляне на някои или всички наши активи, независимо дали като действащо предприятие или като част от банкрут, ликвидация или подобна процедура, при която Личните данни, държани от Нас за нашите потребители на Услугата, са сред прехвърлените активи.</p>
            </li>
            <li>
            <p><strong>За други цели</strong>: Може да използваме вашата информация за други цели, като анализ на данни, идентифициране на тенденции в използването, определяне на ефективността на нашите промоционални кампании и за оценка и подобряване на нашата Услуга, продукти, услуги, маркетинг и вашия опит.</p>
            </li>
            </ul>
            <p>Може да споделяме вашата лична информация в следните ситуации:</p>
            <ul>
            <li><strong>С доставчици на услуги:</strong> Може да споделяме вашата лична информация с доставчици на услуги, за да наблюдаваме и анализираме използването на нашата Услуга, за да се свържем с Вас.</li>
            <li><strong>За бизнес трансфери:</strong> Може да споделяме или прехвърляме вашата лична информация във връзка с, или по време на преговори за, всяко сливане, продажба на активи на Компанията, финансиране или придобиване на всички или част от нашия бизнес от друга компания.</li>
            <li><strong>С афилиати:</strong> Може да споделяме вашата информация с нашите афилиати, като в този случай ще изискваме от тези афилиати да спазват тази Политика за поверителност. Афилиатите включват нашата компания-майка и всякакви други дъщерни дружества, партньори в съвместни предприятия или други компании, които контролираме или които са под общ контрол с Нас.</li>
            <li><strong>С бизнес партньори:</strong> Може да споделяме вашата информация с нашите бизнес партньори, за да Ви предложим определени продукти, услуги или промоции.</li>
            <li><strong>С други потребители:</strong> когато споделяте лична информация или по друг начин взаимодействате в публичните области с други потребители, такава информация може да бъде видяна от всички потребители и може да бъде публично разпространена извън.</li>
            <li><strong>С вашето съгласие</strong>: Може да разкрием вашата лична информация за всяка друга цел с вашето съгласие.</li>
         </ul>
         <p>----------------</p>
         <h1 id="english-version">Privacy Policy</h1>
         <p>Last updated: October 27, 2024</p>
         <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
         <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Free Privacy Policy Generator</a>.</p>
         <h2>Interpretation and Definitions</h2>
         <h3>Interpretation</h3>
         <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
         <h3>Definitions</h3>
         <p>For the purposes of this Privacy Policy:</p>
         <ul>
         <li>
         <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
         </li>
         <li>
         <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
         </li>
         <li>
         <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Studio Reborn LLC, Sofia.</p>
         </li>
         <li>
         <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
         </li>
         <li>
         <p><strong>Country</strong> refers to:  Bulgaria</p>
         </li>
         <li>
         <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
         </li>
         <li>
         <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
         </li>
         <li>
         <p><strong>Service</strong> refers to the Website.</p>
         </li>
         <li>
         <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
         </li>
         <li>
         <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
         </li>
         <li>
         <p><strong>Website</strong> refers to Oopsy.app, accessible from <a href="https://oopsy.app" rel="external nofollow noopener" target="_blank">https://oopsy.app</a></p>
         </li>
         <li>
         <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
         </li>
         </ul>
         <h2>Collecting and Using Your Personal Data</h2>
         <h3>Types of Data Collected</h3>
         <h4>Personal Data</h4>
         <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
         <ul>
         <li>
         <p>Email address</p>
         </li>
         <li>
         <p>First name and last name</p>
         </li>
         <li>
         <p>Usage Data</p>
         </li>
         </ul>
         <h4>Usage Data</h4>
         <p>Usage Data is collected automatically when using the Service.</p>
         <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
         <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
         <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
         <h4>Tracking Technologies and Cookies</h4>
         <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
         <ul>
         <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
         <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
         </ul>
         <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">Free Privacy Policy website</a> article.</p>
         <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
         <ul>
         <li>
         <p><strong>Necessary / Essential Cookies</strong></p>
         <p>Type: Session Cookies</p>
         <p>Administered by: Us</p>
         <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
         </li>
         <li>
         <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
         <p>Type: Persistent Cookies</p>
         <p>Administered by: Us</p>
         <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
         </li>
         <li>
         <p><strong>Functionality Cookies</strong></p>
         <p>Type: Persistent Cookies</p>
         <p>Administered by: Us</p>
         <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
         </li>
         </ul>
         <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
         <h3>Use of Your Personal Data</h3>
         <p>The Company may use Personal Data for the following purposes:</p>
         <ul>
         <li>
         <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
         </li>
         <li>
         <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
         </li>
         <li>
         <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
         </li>
         <li>
         <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
         </li>
         <li>
         <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
         </li>
         <li>
         <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
         </li>
         <li>
         <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
         </li>
         <li>
         <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
         </li>
         </ul>
         <p>We may share Your personal information in the following situations:</p>
         <ul>
         <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
         <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
         <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
         <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
         <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
         <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
         </ul>
         <h3>Retention of Your Personal Data</h3>
         <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
         <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
         <h3>Transfer of Your Personal Data</h3>
         <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
         <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
         <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
         <h3>Delete Your Personal Data</h3>
         <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
         <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
         <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
         <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
         <h3>Disclosure of Your Personal Data</h3>
         <h4>Business Transactions</h4>
         <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
         <h4>Law enforcement</h4>
         <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
         <h4>Other legal requirements</h4>
         <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
         <ul>
         <li>Comply with a legal obligation</li>
         <li>Protect and defend the rights or property of the Company</li>
         <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
         <li>Protect the personal safety of Users of the Service or the public</li>
         <li>Protect against legal liability</li>
         </ul>
         <h3>Security of Your Personal Data</h3>
         <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
         <h2>Children's Privacy</h2>
         <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
         <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
         <h2>Links to Other Websites</h2>
         <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
         <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
         <h2>Changes to this Privacy Policy</h2>
         <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
         <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
         <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
         <h2>Contact Us</h2>
         <p>If you have any questions about this Privacy Policy, You can contact us:</p>
         <ul>
         <li>By email: legal@oopsy.app</li>
         </ul>
      </div>
   </div>
`
    }
    ;
}
framework7Component.id = '79cede7026';
export default framework7Component;