import {getApps, initializeApp} from 'firebase/app';
import {Functions, HttpsCallable, getFunctions, httpsCallable} from 'firebase/functions';
import {firebaseConfig} from '../login';

export class Page {
   private functions: Functions;

   constructor() {
      const app = (!getApps().length) ? initializeApp(firebaseConfig) : getApps()[0];
      this.functions = getFunctions(app, 'europe-central2');
   }

   protected getFunction(name: string): HttpsCallable {
      // connectFunctionsEmulator(this.functions, 'localhost', 5001);
      return httpsCallable(this.functions, name,);
   }
}
