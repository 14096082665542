import {ComponentContext} from 'framework7/modules/component/component';
import {getAuth} from 'firebase/auth';
import {Page} from './page';
import {Offer, Search} from '../types';
import i18next from 'i18next';

export class SearchPage extends Page {
   start = 0;
   lastSearchTerm = '';
   offers: Offer[] = [];
   ctx: ComponentContext;

   constructor(ctx: ComponentContext) {
      super();

      getAuth().onAuthStateChanged((user) => {
         if (user && !ctx.$('#search').val()) {
            this.getLastSearch().then((lastSearch) => {
               const search = JSON.parse(lastSearch.data as string) as Search;
               if (search.searchQuery) {
                  ctx.$('#sendEmail').prop('checked', search.userEmail);
                  if (!ctx.$('#search').val()) {
                     ctx.$('#search').val(search.searchQuery);
                  }
               }
            });
         }
      });

      this.ctx = ctx;
      ctx.$on('pageInit', () => {
         const queryParams = ctx.$f7router.currentRoute.query;
         if (queryParams.q) {
            ctx.$('#search').val(queryParams.q);
            this.search();
         }
         ctx.$('#search').on('keydown', (event: Event) => {
            if ((event as KeyboardEvent).key === 'Enter') {
               this.search();
            }
         });
         ctx.$('#search').on('click', () => {
            this.search();
         });
         ctx.$('#sort').on('change', () => {
            this.sortAndPrint(this.offers, ctx.$('#sort').val());
         });
         ctx.$('#showMore').on('click', () => {
            this.search();
         });
      });
   }

   private search() {
      const searchTerm = this.ctx.$('#search').val();

      if (searchTerm) {
         if (searchTerm != this.lastSearchTerm) {
            this.start = 0;
            this.lastSearchTerm = searchTerm;
            this.offers = [];
            this.ctx.$('#offers').empty();
         }
         this.ctx.$('.preloader').show();
         this.searchOffers(
            searchTerm,
            this.start,
            this.ctx.$('#sendEmail').prop('checked')).then((result) => {
            if (this.start < 1) {
               this.ctx.$('#offers').empty();
            }
            this.ctx.$('.preloader').hide();
            this.start += 10;

            if (result.data) {
               this.ctx.$('#showMore').show();
               this.ctx.$('#sort').show();
               const offers = JSON.parse(result.data as string);
               if (Array.isArray(offers) && offers.length) {
                  this.offers = this.offers.concat(offers);
                  this.sortAndPrint(this.offers, this.ctx.$('#sort').val());
                  return;
               }
            }

            if (!this.offers.length) {
               this.ctx.$('.searchbar-not-found').show();
            }
         });
      }
   }

   private printOffers(offers: Offer[]) {
      offers?.forEach((offer) => {
         this.ctx.$('#offers').append(`
         <li>
            <div class="item-content">
               <div class="item-media">
                  <a href="${offer.image?.src}" class="item-link external item-content" target="_blank">
                     <img width="70" src="${offer.image?.thumbnail}" />
                  </a>
               </div>
               <div class="item-inner">
                  <a href="${offer.website}" class="item-link external item-content" target="_blank">
                     <div class="item-title-row">
                        <div class="item-title">
                           <div class="item-header">${!isNaN(Number(offer.category)) ? i18next.t('add.category.' + offer.category) : offer.category || ''}</div>
                           ${offer.description}
                           <div class="item-footer">${offer.website}</div>
                        </div>
                     </div>
                  </a>
                  ${offer.currentPrice ? `
                     <span class="chip">
                        <span class="chip-label">${offer.currentPrice || ''} ${offer.currency || ''}</span>
                     </span>
                  ` : ''}
               </div>
            </div>
         </li>
         `);
      });
   }

   private sortAndPrint(offers: Offer[], sortType: number = 0) {
      this.ctx.$('#offers').empty();

      if (sortType === 0) {
         this.printOffers(this.offers);
         return;
      }

      const sortedOffers = offers.slice().sort((offer1: Offer, offer2:Offer) => {
         if (offer1.currentPrice && offer2.currentPrice) {
            return sortType * (offer1.currentPrice - offer2.currentPrice);
         } else if (!offer1.currentPrice && !offer2.currentPrice) {
            return sortType * 1;
         } else if (!offer2.currentPrice) {
            return sortType * -1;
         } else {
            return 0;
         }
      });

      this.printOffers(sortedOffers);
   }

   private searchOffers(searchTerm: string, start: number, sendEmail: boolean) {
      const searchOffers = this.getFunction('offers');
      return searchOffers({q: searchTerm, start, sendEmail});
   }

   private getLastSearch() {
      const getLastSearch = this.getFunction('lastSearch');
      return getLastSearch();
   }
}
