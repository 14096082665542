/** @jsx $jsx */
import { $jsx } from 'framework7';
import i18next from '../../src/i18n.js';
function framework7Component(props, ctx) {
  const welcome = i18next.t('about.welcome');
  const privacy = i18next.t('about.privacy');
  const description = i18next.t('about.description');
  const search = i18next.t('about.search');
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page page-home">

     <!-- Page Content -->
     <div class="page-content content-area pt-40 pb-0">

        <!-- Search backdrop -->
        <div class="searchbar-backdrop"></div>
        <div class="container">
           <div class="top-bar">
              <div class="notification-bar">
                 <div class="info">
                    <span class="text">Hello,</span>
                    <h2 class="userName title"></h2>
                 </div>
              </a>
              <a href="#" class="link panel-open" data-panel="left">
                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="2" width="20" height="3" rx="1.5" fill="#5F5F5F"/>
                    <rect y="18" width="20" height="3" rx="1.5" fill="#5F5F5F"/>
                    <rect x="4" y="10" width="20" height="3" rx="1.5" fill="#5F5F5F"/>
                 </svg>
              </a>
           </div>
           <div>
              <h2>About us</h2>
              <div class="page page-onboading" data-name="home">
               <div class="page-content">
                  <div class="started-swiper-box">
                     <swiper-container pagination class="demo-swiper get-started" space-between="0" slides-per-view="auto">
                        <swiper-slide>
                           <div class="slide-info">
                              <div class="slide-content">
                                 <h2 class="dz-title">${welcome}</h2>
                                 <svg fill="#000000" width="50%" height="50%" viewBox="0 0 32 32"
                                    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1"
                                    xml:space="preserve">
                                    <g transform="matrix(1,0,0,1,-432,-48)">
                                       <g transform="matrix(1.14827,0,0,1.14827,-65.6442,-8.70986)">
                                          <circle cx="445" cy="61" r="9" style="fill:rgb(144,224,239);" />
                                       </g>
                                       <path
                                          d="M452.626,70.009C452.64,70.025 452.655,70.04 452.67,70.056L460.254,77.639C460.644,78.029 461.277,78.029 461.668,77.639C462.058,77.249 462.058,76.615 461.668,76.225L454.084,68.641C454.068,68.625 454.052,68.61 454.035,68.595C455.679,66.629 456.669,64.096 456.669,61.334C456.669,55.079 451.59,50 445.334,50C439.079,50 434,55.079 434,61.334C434,67.59 439.079,72.669 445.334,72.669C448.111,72.669 450.655,71.668 452.626,70.009ZM445.334,52C450.486,52 454.669,56.183 454.669,61.334C454.669,66.486 450.486,70.669 445.334,70.669C440.183,70.669 436,66.486 436,61.334C436,56.183 440.183,52 445.334,52Z"
                                          style="fill:rgb(25,144,167);" />
                                    </g>
                                 </svg>
                                 <p>${description}</p>
                                 <p>
                                    <a href="/privacy/">${privacy}</a>
                                 </p>
                              </div>
                              <div class="toolbar toolbar-bottom container footer-button">
                                 <a href="/search/" class="button-large rounded-xl button button-fill">${search}</a>
                              </div>
                           </div>
                        </swiper-slide>
                     </swiper-container>
                  </div>
               </div>
            </div>
           </div>
        </div>
     </div>
  </div>
`
    }
    ;
}
framework7Component.id = 'd474b94e53';
export default framework7Component;