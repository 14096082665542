import i18next from 'i18next';

i18next.init({
   lng: 'bg',
   debug: true,
   resources: {
      en: {
         translation: {
            'index.appName': 'oopsy.app',
            'index.about': 'About us',
            'index.add': 'Add',
            'index.search': 'Search price',
            'index.login': 'Login',
            'index.logout': 'Logout',
            'search.search': 'Search',
            'search.login': 'Login to receive new offers',
            'search.hello': 'Hello',
            'search.bestPrice': 'Find the best price for',
            'search.lastSearches': 'Last searched:',
            'search.notFound': 'Nothing found',
            'search.sendEmail': 'Send email for new offers',
            'search.sortBy': 'Sort by:',
            'search.sortByPriceAscending': 'price ascending',
            'search.sortByPriceDescending': 'price descending',
            'search.sortByRelevance': 'relevance',
            'search.showMore': 'Show more offers',
            'login.skip': '... or continue without logging in',
            'about.welcome': 'Oopsy! We will find you a better price!',
            'about.privacy': 'Privacy policy',
            'about.description': 'Here you can search for the best price for a given product.',
            'about.search': 'Search',
            'add.category.1': 'Animals & Pet Supplies',
            'add.category.537': 'Baby & Toddler',
            'add.category.5181': 'Luggage & Bags',
            'add.category.632': 'Hardware',
            'add.category.141': 'Cameras & Optics',
            'add.category.222': 'Electronics',
            'add.category.412': 'Food, Beverages & Tobacco',
            'add.category.469': 'Health & Beauty',
            'add.category.536': 'Home & Garden',
            'add.category.922': 'Office Supplies',
            'add.category.166': 'Apparel & Accessories',
            'add.category.8': 'Arts & Entertainment',
            'add.category.783': 'Media',
            'add.category.436': 'Furniture',
            'add.category.5605': 'Religious & Ceremonial',
            'add.category.2092': 'Software',
            'add.category.1239': 'Toys & Games',
            'add.category.988': 'Sporting Goods',
            'add.category.888': 'Vehicles & Parts',
            'add.category.772': 'Mature',
            'add.category.111': 'Business & Industrial',
            'add.description': 'Description',
            'add.currentPrice': 'Current price',
            'add.previousPrice': 'Previous price',
            'add.currency': 'Currency',
            'add.website': 'Website',
            'add.save': 'Add',
            'add.save.success': 'Price added successfully',
            'add.save.error': 'Current price cannot be greater than previous price',
         },
      },
      bg: {
         translation: {
            'index.appName': 'oopsy.app',
            'index.about': 'За нас',
            'index.add': 'Добави',
            'index.search': 'Търси',
            'index.login': 'Вход',
            'index.logout': 'Изход',
            'search.search': 'Търси',
            'search.login': 'Влезте за да ви пращаме оферти',
            'search.hello': 'Здравейте',
            'search.bestPrice': 'Намерете най-добрата цена за',
            'search.lastSearches': 'Последнo търсени:',
            'search.notFound': 'Нищо не е намерено',
            'search.sendEmail': 'Изпрати имейл за нови оферти',
            'search.sortBy': 'Сортирай по:',
            'search.sortByPriceAscending': 'цена възходящо',
            'search.sortByPriceDescending': 'цена низходящо',
            'search.sortByRelevance': 'релевантност',
            'search.showMore': 'Покажи още оферти',
            'login.skip': '... или продължете без да влезете в профила си',
            'about.welcome': 'Oopsy! Ще ви намерим по-добра цена!',
            'about.privacy': 'Политика за поверителност',
            'about.description': 'Тук можете да търсите най-добрата цена за даден продукт.',
            'about.search': 'Търси',
            'add.category.1': 'Животни и домашни любимци',
            'add.category.537': 'Бебета и деца',
            'add.category.5181': 'Чанти и куфари',
            'add.category.632': 'Хардуер',
            'add.category.141': 'Камери и оптика',
            'add.category.222': 'Електроника',
            'add.category.412': 'Храни, напитки и тютюневи изделия',
            'add.category.469': 'Здраве и красота',
            'add.category.536': 'Дом и градина',
            'add.category.922': 'Офис материали',
            'add.category.166': 'Облекло и аксесоари',
            'add.category.8': 'Изкуство и забавления',
            'add.category.783': 'Медии',
            'add.category.436': 'Мебели',
            'add.category.5605': 'Религиозни и церемониални',
            'add.category.2092': 'Софтуер',
            'add.category.1239': 'Играчки и игри',
            'add.category.988': 'Спортни стоки',
            'add.category.888': 'Превозни средства и части',
            'add.category.772': 'За възрастни',
            'add.category.111': 'Бизнес и индустрия',
            'add.description': 'Oписание',
            'add.currentPrice': 'Tekуща цена',
            'add.previousPrice': 'Предишна цена',
            'add.currency': 'Валута',
            'add.website': 'Уебсайт',
            'add.save': 'Добави',
            'add.save.success': 'Цената беше добавена успешно',
            'add.save.error': 'Текущата цена не може да бъде по-голяма от предишната',
         },
      },
   },
});

export default i18next;
