import Framework7, {Dom7} from 'framework7/bundle';
import {getCurrentUser} from './login.ts';
import routes from './routes';
import i18next from './i18n';

const APP_VERSION = '0.9';

// Dom7
const $ = Dom7;

// Init App
const app = new Framework7({
   id: 'io.framework7.testapp',
   el: '#app',
   colors: {
      // specify primary color theme
      primary: '#ff0000',
   },
   view: {
      browserHistory: true,
   },
   // store.js,
   // store: store,
   // routes.js,
   routes: routes,
   popup: {
      closeOnEscape: true,
   },
   sheet: {
      closeOnEscape: true,
   },
   popover: {
      closeOnEscape: true,
   },
   actions: {
      closeOnEscape: true,
   },
   vi: {
      placementId: 'pltd4o7ibb9rc653x14',
   },
   on: {
      pageInit: () => {
         $('#version').text(i18next.t('index.appName') + ' ' + APP_VERSION);

         $('#bg').click(() => {
            i18next.changeLanguage('bg');
            app.views.main.router.navigate('/about/');
         });
         $('#en').click(() => {
            i18next.changeLanguage('en');
            app.views.main.router.navigate('/about/');
         });

         $('#menuAbout').text(i18next.t('index.about'));
         $('#menuPrivacy').text(i18next.t('index.privacy'));
         $('#menuAdd').text(i18next.t('index.add'));
         $('#menuSearch').text(i18next.t('index.search'));
         $('#menuLogin').text(i18next.t('index.login'));
         $('#menuLogout').text(i18next.t('index.logout'));

         getCurrentUser().then((user) => {
            $('.userName').text(user?.displayName);
            if (user) {
               $('#login').hide();
               $('#logout').show();
            } else {
               $('#login').show();
               $('#logout').hide();
            }
         });
      },
   },
});
