import {getApps, initializeApp} from 'firebase/app';
import {getAuth, User} from 'firebase/auth';

export const firebaseConfig = {
   apiKey: 'AIzaSyBGWM7WmPq6I7IBR4kAdLZPLfVAitxbHC0',
   authDomain: 'oopsy-app.firebaseapp.com',
   projectId: 'oopsy-app',
   storageBucket: 'oopsy-app.appspot.com',
   messagingSenderId: '409861724524',
   appId: '1:409861724524:web:e35c358660ff324debfb49',
   measurementId: 'G-5W5PBWZGNN',
};

let userLoaded = false;

export function getCurrentUser() {
   if (!getApps().length) {
      initializeApp(firebaseConfig);
   }

   return new Promise<User | null>((resolve, reject) => {
      if (userLoaded) {
         resolve(getAuth().currentUser);
      }
      const unsubscribe = getAuth().onAuthStateChanged((user) => {
         userLoaded = true;
         unsubscribe();
         resolve(user);
      }, reject);
   });
}
